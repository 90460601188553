const throttle = (func, delay) => {
  let throttled;
  let last;
  return (...args) => {
    if (!throttled) {
      throttled = true;
      setTimeout(() => {
        throttled = false;
      }, delay);
      func.apply(this, args);
      last = args[0];
    }
    return last;
  };
};

module.exports = { throttle };

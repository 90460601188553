import './css/autoaddress-style.css';

import magnifyingGlass from './assets/magnifying-glass.png';
import { ApiService, endpoints, linkTypes } from './modules/services/api-service';
import { AddressFormBuilder } from './modules/autoaddress-form';
import { Callbacks } from './modules/callbacks';
import componentFactory from './modules/component-factory';
import { ErrorHandler } from './modules/errors';
import { integrationType } from './modules/integrations';
import { getDefaultOptions, Options } from './modules/options';
import { Selector } from './modules/helpers/selector';
import { Settings, themes } from './modules/settings';
import { autoaddressListCutoff } from './modules/autoaddress-list-cutoff';
import { debounce } from './modules/helpers/debounce';
import { throttle } from './modules/helpers/throttle';
import Utils from './modules/helpers/utils';

export default function Autoaddress(optionData) {
  // elements
  let element, inlineElement, input, container, addressForm;

  // options
  let options, defaultOptions, jsInitOptions, configOptions, userOptions;

  let settings;

  // instantiated objects
  let apiService;
  let callbacks;
  let selector;
  let errorHandler;
  let addressFormBuilder;

  // boolean to determine whether or not the Autoaddress Form is being displayed
  let showingForm;
  let addressFormLayoutLink;

  init(optionData);

  // #region init functions

  function init(optionData) {
    initOptions(optionData);
    initSettings();
    updateOptions();

    selector = new Selector();

    callbacks = new Callbacks(options);
    errorHandler = new ErrorHandler(callbacks);
    apiService = new ApiService(
      options.apiUrl,
      settings.version,
      options.token,
      errorHandler,
      options.useAuthorizationHeaderForApiKey,
      options.useAuthorizationHeaderForToken,
      callbacks,
      selector
    );

    setInput();
    configureOptions();
    configureSettings();
    validateOptions();

    postInit();
  }

  async function postInit() {
    // prevent control from rendering twice in the same div
    if (element?.querySelector('#autoaddress-container')) {
      element.removeChild(element.querySelector('#autoaddress-container'));
    }

    if (!settings.wrapperIsInput && element) {
      createComponents(element, options);
    }

    configureIntegrations();

    if (options.token) {
      apiService.setToken(options.token);
      await getJSControlInit();
    } else {
      await createToken(options.apiKey);
    }
    window.addEventListener('resize', resizeTextAreas);

    settings.initCompleted = true;
    callbacks.onPostInit(apiService.token);
  }

  function initSettings() {
    settings = Settings.getDefaultSettings();
  }

  function initOptions(initOptions) {
    defaultOptions = getDefaultOptions();
    jsInitOptions = new Options();
    configOptions = new Options();
    userOptions = initOptions;
  }

  function initInternalCallbacks() {
    callbacks.onRebuildAddressForm = link => {
      buildAddressFormFromLink(link);
    };
    callbacks.onSubmitToFormatEnteredAddress = params => {
      submitToFormatEnteredAddress(params);
    };
  }

  // #endregion

  async function getJSControlInit() {
    let params = {
      language: options.language,
      country: options.country,
      latitude: options.latitude,
      longitude: options.longitude,
      h3Index: options.h3Index,
      isSearchAllowed: options.isSearchAllowed,
    };
    let initResponse = await apiService.fetch(
      endpoints.JS_CONTROL_INIT,
      params,
      response => {
        settings.searchMessage = response.searchMessage;

        if (
          settings.wrapperIsInput &&
          options.isSearchAllowed !== false &&
          response.isSearchAllowed
        ) {
          attachToInput();
        }

        setContainerSizeClass(container);
        initAddressFormBuilder();
        initInternalCallbacks();

        handleJSInitResponse(response, options.integrationType);

        callbacks.onPostJsInit(response);
        callbacks.onPostJsControlInit(response);
      },
      null
    );
    if (!initResponse.ok) {
      hideSearchBar();
      showCustomerFields();
    }
  }

  function resizeTextAreas() {
    const txtareas = selector.elementQuerySelectorAll('.autoaddress-textarea');
    for (let i = 0; i < txtareas.length; i++) {
      let field = txtareas[i];
      field.style.height = 0;
      field.style.height = Math.max(field.scrollHeight, settings.inputHeight) + 'px';
    }
    setContainerSizeClass(container);
  }

  function setContainerSizeClass(container) {
    if (container) {
      let containerSizeClass = getContainerSizeClass(container.clientWidth);
      let containerClasses = ['autoaddress-size-sm', 'autoaddress-size-md', 'autoaddress-size-lg'];
      containerClasses.forEach(item => {
        container.classList.remove(item);
      });
      container.classList.add(containerSizeClass);
    }
  }

  function getContainerSizeClass(containerSize) {
    if (containerSize < settings.smCutoff) return 'autoaddress-size-sm';
    if (containerSize < settings.mdCutoff) return 'autoaddress-size-md';
    if (containerSize >= settings.mdCutoff) return 'autoaddress-size-lg';
    return 'autoaddress-size-md';
  }

  function updateOptions() {
    options = Utils.extend(defaultOptions, jsInitOptions, configOptions, userOptions);
  }

  function initAddressFormBuilder() {
    addressFormBuilder = new AddressFormBuilder(callbacks, apiService);
  }

  function configureOptions() {
    if (options.integrationType == integrationType.INLINE && !settings.wrapperIsInput) {
      errorHandler.handleError(
        errorHandler.error.INCORRECT_INPUT_TYPE_FOR_INTEGRATION_TYPE,
        options.integrationType
      );
    }

    if (settings.wrapperIsInput) {
      configOptions.integrationType = integrationType.INLINE;
    }

    if (
      options.integrationType == integrationType.INLINE &&
      inlineElement &&
      inlineElement.placeholder
    ) {
      configOptions.placeholder = inlineElement.placeholder;
    }

    if (options.search) {
      setSearch(options.search);
    }

    updateOptions();
  }

  function configureIntegrations() {
    if (
      options.integrationType == integrationType.AUTOADDRESS_FORM ||
      integrationType.COMBO.includes(options.integrationType)
    ) {
      hideCustomerFields();
    } else {
      showCustomerFields();
    }
  }

  function configureSettings() {
    if (settings.theme == themes.MATERIAL) {
      settings.inputHeight = 56;
    } else {
      settings.inputHeight = 36;
    }
  }

  function validateOptions() {
    if (options.limit) {
      // Autocomplete endpoint has a limit on this. Returns 400 if under 3... Prevent them from getting this.
      if (options.limit < 3) {
        options.limit = 3;
      }
    }
  }

  function setInput() {
    let wrapper;
    let queryValue;
    if (options.elementId) {
      queryValue = options.elementId;
      wrapper = document.getElementById(queryValue);
    } else if (options.elementSelector) {
      queryValue = options.elementSelector;
      wrapper = document.querySelector(queryValue);
    } else {
      errorHandler.handleError(errorHandler.error.ELEMENT_ID_NOT_PROVIDED);
    }

    if (wrapper) {
      if (wrapper.nodeName.toLowerCase() === 'input') {
        inlineElement = wrapper;
        settings.wrapperIsInput = true;
      } else {
        element = wrapper;
        settings.wrapperIsInput = false;
      }
    } else {
      errorHandler.handleError(errorHandler.error.WRAPPER_ELEMENT_NOT_FOUND, queryValue);
    }
  }

  function autoScroll() {
    // if (callbacks.onClickInputField(getInputValue()) !== false) {
    if (
      window.innerWidth < settings.windowInnerWidthCutoff ||
      window.innerHeight < settings.windowInnerHeightCutoff
    ) {
      let rect = container.getBoundingClientRect();

      //get the window height minus the distance from autoaddress container to the browser nav (rect.top)
      let position = window.innerHeight - rect.top;
      let paddingTop = options.autoScrollOffset;

      if (!options.autoScrollOffset == 0) {
        setTimeout(() => {
          window.scroll({
            top: position - paddingTop,
            behavior: 'smooth',
          });
        }, 50);
      }
    }
    // }
  }

  function attachToInput() {
    let elementStyle = inlineElement.currentStyle || window.getComputedStyle(inlineElement);
    let left = parseInt(elementStyle.marginLeft, 10);
    let inputWidth = elementStyle.width;

    container = componentFactory('div', {
      id: 'autoaddress-container',
      class: 'autoaddress-inline ' + options.theme,
      style:
        'float: left; bottom:' +
        elementStyle.marginBottom +
        '; left:' +
        left +
        'px;' +
        ' width:' +
        inputWidth +
        ';',
    });

    selector.setContainer(container);

    // add aa container after input
    inlineElement.parentNode.insertBefore(container, inlineElement.nextSibling);

    settings.initialPlaceholder = inlineElement.placeholder;
    inlineElement.placeholder = settings.placeholder;

    // remove old event listeners before attaching new ones
    detachListenerForInput();
    attachListenerForInput();

    inlineElement.setAttribute('role', 'combobox');
    inlineElement.setAttribute('aria-expanded', false);
    inlineElement.setAttribute('aria-controls', 'autoadress-list');
    inlineElement.setAttribute('aria-autocomplete', 'list');
  }

  function detachListenerForInput() {
    inlineElement.removeEventListener('input', inlineAutoComplete);
    inlineElement.removeEventListener('keydown', handleKeydown);
  }

  function attachListenerForInput() {
    inlineElement.addEventListener('input', inlineAutoComplete);
    inlineElement.addEventListener('keydown', handleKeydown);
  }

  function inlineAutoComplete() {
    if (this.value.length > 3) {
      autocomplete(this.value);
    } else if (this.value.length < 1) {
      clearList();
    }
  }

  function createComponents() {
    // Create Autocomplete Input
    input = componentFactory('input', {
      type: 'text',
      id: 'autoaddress-input',
      placeholder: settings.placeholder,
      autocomplete: 'off',
      autocorrect: 'off',
      autocapitalize: 'none',
      spellcheck: 'false',
      // Ensure 1Password does not try to fill the input
      'data1p-ignore': '',
      role: 'combobox',
      'aria-expanded': false,
      'aria-controls': 'autoaddress-list',
      'aria-autocomplete': 'list',
    });

    input.addEventListener('input', function () {
      if (callbacks.onPreAutocomplete(this.value) !== false) {
        if (this.value.length > 3) {
          autocomplete(this.value);
        }
      }
      if (this.value.length < 1) {
        clearList();
      }
    });

    input.addEventListener('click', function () {
      callbacks.onClickInputField(input.value);
    });

    let searchIcon = componentFactory('img', {
      class: 'magnifying-glass',
      src: magnifyingGlass,
    });

    let searchLoading = componentFactory('div', {
      class: 'spinner',
    });

    let button = componentFactory(
      'button',
      {
        type: 'button',
        id: 'autoaddress-button',
        dataloading: 'false',
        'aria-label': 'Search button',
      },
      searchIcon,
      searchLoading
    );

    button.addEventListener('click', function () {
      if (callbacks.onClickSearchButton(input.value) !== false) {
        if (input.value.length > 3) {
          search(input.value);
        }
        if (input.value.length < 1) {
          clearList();
        }
      }
    });

    let jsControlDiv = componentFactory(
      'div',
      {
        class: 'autoaddress-input-control',
      },
      input,
      button
    );

    jsControlDiv.addEventListener('keydown', handleKeydown);

    container = componentFactory(
      'div',
      {
        id: 'autoaddress-container',
        class: options.theme,
      },
      jsControlDiv
    );

    selector.setContainer(container);

    element.appendChild(container);
  }

  function handleKeydown(event) {
    const key = event.key;
    const suggestions = selector.elementQuerySelectorAll('li');
    const selected = suggestions?.[settings.selectedIndex];

    if (suggestions.length === 0) {
      return;
    }

    switch (key) {
      case 'ArrowUp':
        event.preventDefault();
        navigateOptionsList(-1);
        break;
      case 'ArrowDown':
        event.preventDefault();
        navigateOptionsList(1);
        break;
      case 'Enter': {
        event.preventDefault();
        handleEnter(event, selected);
        break;
      }
      case 'Escape':
      case 'Tab':
        hideList();
        break;
      default:
        break;
    }
  }

  function handleEnter(event, selected) {
    if (selected) {
      const id = selected?.getAttribute('id');
      const isManualEntry = id === 'autoaddress-manual-enter';

      if (isManualEntry) {
        handleSelectEnterManually(selected, options.integrationType);
      } else if (settings.selectedIndex !== -1) {
        handleSelection(event, selected);
      }
    } else {
      search(event.target.value);
    }
  }

  // Handle selection of autocomplete item by click or enter
  function handleSelection(event, listItem) {
    let link = listItem?.getAttribute('data-link');
    if (link) {
      if (callbacks.onSelectItem(link) !== false) {
        getAddressLink(link).then(response => callbacks.onSelectItemResponse(response));
      }
    }
    event.preventDefault();
  }

  function navigateOptionsList(offset) {
    const lis = selector.elementQuerySelectorAll('li');

    if (lis.length === 0) {
      return;
    }

    // Remove the selected class from the currently selected item
    if (settings.selectedIndex !== -1) {
      lis[settings.selectedIndex].classList.remove('selected');
      lis[settings.selectedIndex].setAttribute('aria-selected', 'false');
    }

    // Update the selectedOptionIndex
    const newIndex = settings.selectedIndex + offset;

    if (newIndex >= 0 && newIndex < lis.length) {
      settings.selectedIndex = newIndex;
    } else if (newIndex < 0) {
      input
        ? input.removeAttribute('aria-activedescendant')
        : inlineElement.removeAttribute('aria-activedescendant');
      settings.selectedIndex = -1;
    } else {
      settings.selectedIndex = lis.length - 1; // Stop at the bottom
    }

    const newSelected = lis[settings.selectedIndex];

    if (newSelected) {
      // Update the aria-selected attribute for the newly selected item
      newSelected.setAttribute('aria-selected', 'true');
      // Update the input so it knows what option has been selected
      input
        ? input.setAttribute('aria-activedescendant', newSelected.id)
        : inlineElement.setAttribute('aria-activedescendant', newSelected.id);
      // Add the selected class to the newly selected item
      newSelected.classList.add('selected');
      // Ensure the selected item is in view
      const container = document.getElementById('autoaddress-list');
      const containerRect = container.getBoundingClientRect();
      const selectedRect = newSelected.getBoundingClientRect();

      const stickyItemHeight =
        document.getElementById('autoaddress-manual-enter')?.offsetHeight ?? 0;

      // If the selected item is above the visible area, scroll up
      if (selectedRect.top < containerRect.top) {
        container.scrollTop -= containerRect.top - selectedRect.top;
      }

      // If the selected item is below the visible area, scroll down
      if (selectedRect.bottom > containerRect.bottom - stickyItemHeight) {
        container.scrollTop += selectedRect.bottom - containerRect.bottom + stickyItemHeight;
      }
    }
  }

  function createList(response, sourceType) {
    // Reset selected index for new autocomplete list
    settings.selectedIndex = -1;
    let items = response.options;
    let listItems = [];
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        let itemChildElements = [];
        let itemClass = 'autoaddress-dropdown-item';
        let addressLineElements = getAddressLineElements(items[i]);
        let textDiv = componentFactory(
          'div',
          {
            class: 'autoaddress-dropdown-item-text',
          },
          ...addressLineElements
        );
        let ariaLabel = items[i].value;

        itemChildElements.push(textDiv);
        if (items[i].suffix) {
          itemClass += ' autoaddress-dropdown-item-has-options';

          let italicText = componentFactory('em', {
            class: 'italic-text',
            innerHTML: items[i].suffix,
          });

          let addressCountDiv = componentFactory(
            'div',
            {
              class: 'autoaddress-item-list',
            },
            italicText
          );

          let arrowImg = componentFactory('p', {
            class: 'arrow italic-text',
            innerHTML: '<b>&gt;</b>',
          });

          itemChildElements.push(addressCountDiv);
          itemChildElements.push(arrowImg);

          if (items[i].value.includes('A - Z')) {
            ariaLabel = `Business names. Click to view ${items[i].suffix}`;
          } else {
            // Suffix looks like '48 addresses'. This adds that context to the aria label so user knows more addresses lie within
            ariaLabel = `${ariaLabel}. Click to view ${items[i].suffix}`;
          }
        }

        let listItem = componentFactory(
          'li',
          {
            id: 'autoaddress-item-' + i,
            class: itemClass,
            datalink: items[i].link.href,
            'aria-selected': false,
            role: 'option',
            'aria-label': ariaLabel,
            tabindex: -1,
          },
          ...itemChildElements
        );

        listItem.addEventListener('mousedown', event => handleSelection(event, listItem));

        if (i == 0 && sourceType == linkTypes.DRILLDOWN) {
          listItem.classList.add('autoaddress-back');
          const santitisedAriaLabel = ariaLabel.replace('< ', '');
          listItem.setAttribute(
            'aria-label',
            `Options for ${santitisedAriaLabel}. Click here to go back to your previous results`
          );
          listItems.unshift(listItem);
        } else {
          listItems.push(listItem);
        }
      }
    }
    let manualEntryLink = '';
    let manualEntryTitle = '';

    response.links.forEach(element => {
      if (element.rel == endpoints.ADDRESS_FORM_LAYOUT) {
        manualEntryLink = element.href;
        manualEntryTitle = element.title;
      }
    });

    let enterManually = createEnterManually(manualEntryLink, manualEntryTitle);
    listItems.push(enterManually);

    let parentList = [];

    let list = componentFactory(
      'ul',
      {
        id: 'autoaddress-list',
        role: 'listbox',
      },
      ...listItems
    );

    parentList.push(list);

    let parentDivList = componentFactory(
      'div',
      {
        id: 'autoaddress-list-container',
      },
      ...parentList
    );

    //This handles clicking off the autocomplete list.
    document.addEventListener('click', function (event) {
      let isClickInside = input
        ? container
          ? container.contains(event.target) || parentDivList.contains(event.target)
          : false
        : inlineElement.contains(event.target) || parentDivList.contains(event.target);

      if (!isClickInside) {
        hideList();
      }
    });

    return parentDivList;
  }

  function getAddressLineElements(listItem) {
    let lineElements = [];

    if (listItem.format.highlights.length > 0 && listItem.format.highlights.length % 2 == 0) {
      if (listItem.format.highlights[0] > 0) {
        let standardBefore = componentFactory('span', {
          class: 'addressline',
          innerHTML: listItem.value.substring(0, listItem.format.highlights[0]),
        });

        lineElements.push(standardBefore);
      }

      //TODO: loop here would look at highlight ints in pairs, add to array in alternating bold/standard and
      let bold = componentFactory('strong', {
        class: 'bold-addressline',
        innerHTML: listItem.value.substring(
          listItem.format.highlights[0],
          listItem.format.highlights[1]
        ),
      });

      lineElements.push(bold);
    }

    let endIndex = listItem.format.highlights[1] ?? 0;

    let standardAfter = componentFactory('span', {
      class: 'addressline',
      innerHTML: listItem.value.substring(endIndex),
    });

    //check if back header and change class to use bold-addressline.
    if (listItem.format.highlights[1] == 1) {
      standardAfter = componentFactory('span', {
        class: 'bold-addressline',
        innerHTML: listItem.value.substring(endIndex),
      });
    }

    lineElements.push(standardAfter);

    if (listItem.format.lineBreaks && listItem.format.lineBreaks.length > 0) {
      let lineBreakStartPosition;
      let hasSplit = false;
      lineBreakStartPosition = listItem.format.lineBreaks[0];

      if (lineBreakStartPosition > 0) {
        lineElements.forEach(element => {
          if (lineBreakStartPosition < element.innerText.length && !hasSplit) {
            let beforeBreak = element.innerText.slice(0, lineBreakStartPosition);
            element.innerText = '';
            if (lineBreakStartPosition > 0) {
              let beforeBreakChild = componentFactory('span', {
                class: 'autoaddress-upper-line',
                innerHTML: beforeBreak,
              });
              element.appendChild(beforeBreakChild);
            }
            let lineBreak = componentFactory('br');
            element.appendChild(lineBreak);
            hasSplit = true;
          }
        });
      }
    }
    return lineElements;
  }

  function getInputValue() {
    if (settings.wrapperIsInput) {
      return inlineElement.value;
    }
    return input.value;
  }

  function hasInitCompleted() {
    if (settings.initCompleted) {
      return true;
    }
    return false;
  }

  function createEnterManually(manualEntryLink, manualEntryTitle) {
    let enterManually = componentFactory('li', {
      id: 'autoaddress-manual-enter',
      class: 'autoaddress-dropdown-item',
      innerHTML: manualEntryTitle,
      datalink: manualEntryLink,
      role: 'option',
      'aria-label': manualEntryTitle.replace('>', ''),
      tabindex: -1,
    });

    function getInputValue() {
      if (settings.wrapperIsInput) {
        return inlineElement.value;
      }
      return input.value;
    }

    enterManually.addEventListener('click', function () {
      if (callbacks.onSelectEnterAddressManually(getInputValue()) !== false) {
        handleSelectEnterManually(this, options.integrationType);
      }
    });
    return enterManually;
  }

  function addressFormLayoutCallback(response, lookupResponse = null) {
    try {
      clearList();
      clearForm();

      addressForm = addressFormBuilder.createAddressForm(
        response,
        response.selectedCountryKey,
        settings,
        lookupResponse,
        options
      );

      selector.elementQuerySelector('.autoaddress-input-control').style.display = 'none';
      settings.selectedCountry = response.selectedCountryKey;
      container.appendChild(addressForm);

      resizeTextAreas();
    } catch (err) {
      errorHandler.handleError(errorHandler.error.AUTOADDRESS_FORM_FAILED_TO_BUILD, err);
    }
  }

  function buildAddressFormFromLink(
    link,
    lookupResponse = null,
    addressFormSetAddressCallback = null
  ) {
    // Clear cached lookup response if the user rebuilds form with no previous lookup i.e. change country
    if (!lookupResponse) {
      settings.selectedAddress = null;
    }
    showingForm = true;

    let params = {
      link: link,
    };

    if (callbacks.onPreAutoaddressFormLayout(link) !== false) {
      apiService.fetch(endpoints.LINK, params, response => {
        let addressFormResponse = callbacks.onPostAutoaddressFormLayout(response);
        response = addressFormResponse ? addressFormResponse : response;
        addressFormLayoutCallback(response, lookupResponse);
        addressFormSetAddressCallback?.();
      });
    }
  }

  function submitToFormatEnteredAddress(params) {
    params.language = options.language;
    params.country = options.country;
    params.selectedCountry = settings.selectedCountry ?? null;
    params.selectedLanguage = settings.selectedLanguage ?? null;

    if (callbacks.onPreFormatEnteredAddress(params) !== false) {
      apiService.fetch(endpoints.FORMAT_ENTERED_ADDRESS, params, response => {
        let formatAddressResponse = callbacks.onPostFormatEnteredAddress(response);
        response = formatAddressResponse ? formatAddressResponse : response;
        if (options.onPostLookup) {
          callbacks.onPostLookup(response);
        }
        if (options.onAddressResult) {
          // Combine Lookup API (if present) address with formatted address
          callbacks.onAddressResult(Utils.getMergedAddress(settings.selectedAddress, response));
        }
      });
    }
  }

  // state functions
  function clearList() {
    removeElement('autoaddress-list-container');
    removeElement('autoaddress-back');
    removeElement('autoaddress-list');
    if (input) {
      input.classList.remove('display-list');
      input['ariaExpanded'] = false;
      input.removeAttribute('aria-activedescendant');
      let button = selector.elementQuerySelector('#' + 'autoaddress-button');
      button.classList.remove('display-list');
    } else {
      inlineElement['ariaExpanded'] = false;
      inlineElement.removeAttribute('aria-activedescendant');
    }
  }

  function displayList(list) {
    removeElement('autoaddress-list-container');
    removeElement('autoaddress-back');
    removeElement('autoaddress-list');
    if (!showingForm) {
      container.appendChild(list);
      autoaddressListCutoff(list, settings.dropDownCutoff, settings.smallViewport);
      //blends autocomplete div into js control by removing the border radius
      if (input) {
        input.classList.add('display-list');
        input['ariaExpanded'] = true;
        let button = selector.elementQuerySelector('#' + 'autoaddress-button');
        button.classList.add('display-list');
      } else {
        inlineElement['ariaExpanded'] = true;
      }
    }
  }

  function hideList() {
    if (input && selector.getContainer()) {
      input.classList.remove('display-list');
      input['ariaExpanded'] = false;
      input.removeAttribute('aria-activedescendant');
      let button = selector.elementQuerySelector('#' + 'autoaddress-button');
      button.classList.remove('display-list');
      removeElement('autoaddress-list-container');
    } else if (inlineElement && selector.getContainer()) {
      inlineElement['ariaExpanded'] = false;
      inlineElement.removeAttribute('aria-activedescendant');
      removeElement('autoaddress-list-container');
    }
  }

  function clearForm() {
    removeElement('autoaddress-form-container');
  }

  function removeElement(elementId) {
    let el = selector.elementQuerySelector('#' + elementId);
    if (el) {
      el.remove();
    }
  }

  // api functions
  function callAutocomplete(inputVal) {
    settings.itemSelected = false;
    if (callbacks.onPreAutocomplete(inputVal) !== false) {
      let params = {
        address: inputVal,
      };
      params = appendOptionalParams(params);
      let autocompleteCall = () => {
        apiService.fetch(endpoints.AUTOCOMPLETE, params, (response, callCount) => {
          if (
            callCount > apiService.lastCallCount &&
            !settings.itemSelected &&
            getInputValue().length > 3
          ) {
            let autocompleteResponse = callbacks.onPostAutocomplete(response);
            response = autocompleteResponse ? autocompleteResponse : response;
            let list = createList(response, endpoints.AUTOCOMPLETE);
            displayList(list);
            if (apiService.lastCallCount === 0) {
              autoScroll();
            }
            apiService.lastCallCount = callCount;
          }
        });
      };
      if (apiService.hasToken()) {
        autocompleteCall();
      } else {
        callbacks.onTokenReceived = autocompleteCall;
      }
    }
  }

  let autocomplete = inputVal => callAutocomplete(inputVal);

  if (options.useThrottle) {
    const autocompleteThrottled = throttle(callAutocomplete, settings.throttleDelay);
    const autocompleteDebounced = debounce(callAutocomplete, settings.debounceDelay);

    autocomplete = inputVal => {
      let last = autocompleteThrottled(inputVal);
      autocompleteDebounced(last, inputVal);
    };
  }

  function search(input) {
    if (callbacks.onPreSearch(input)) {
      let params = {
        address: input,
      };
      params = appendOptionalParams(params);
      apiService.fetch(endpoints.SEARCH, params, (response, callCount) => {
        if (callCount > apiService.lastCallCount) {
          response = callbacks.onPostSearch(response);
          let list = createList(response, endpoints.SEARCH);
          displayList(list);
          apiService.lastCallCount = callCount;
        }
      });
    }
  }

  function appendOptionalParams(params) {
    if (options.language) {
      params.language = options.language;
    }
    if (options.country) {
      params.country = options.country;
    }
    if (options.latitude && options.longitude) {
      params.latitude = options.latitude;
      params.longitude = options.longitude;
    }
    if (options.h3Index) {
      params.h3Index = options.h3Index;
    }
    if (options.limit) {
      params.limit = options.limit.toString();
    }
    return params;
  }

  function getAddressLink(link) {
    settings.itemSelected = true;

    let canFetch = false;

    let linkType = getLinkType(link);

    let responseCallback = response => {
      return response;
    };

    if (
      linkType == linkTypes.DRILLDOWN ||
      linkType == linkTypes.AUTOCOMPLETE ||
      linkType == linkTypes.SEARCH
    ) {
      responseCallback = response => {
        let list = createList(response, linkType);
        displayList(list);
        if (response.options.length == 0) {
          clearList();
        }
        return response;
      };
      canFetch = true;
    }

    if (linkType == linkTypes.LOOKUP) {
      responseCallback = response => {
        let lookupResponse = callbacks.onPostLookup(response);
        callbacks.onAddressResult(response);

        response = lookupResponse ? lookupResponse : response;

        handleLookupResponse(response, options.integrationType);

        return response;
      };
      canFetch = callbacks.onPreLookup(link);
    }

    if (canFetch) {
      return apiService.fetch(
        'link',
        {
          link: link,
        },
        responseCallback
      );
    }
    return apiService.fetchEmpty();
  }

  function getLinkType(link) {
    let pathname = new URL(link).pathname.split('/');
    let linkType = pathname[pathname.length - 1];

    switch (linkType) {
      case linkTypes.AUTOCOMPLETE:
        return linkTypes.AUTOCOMPLETE;
      case linkTypes.DRILLDOWN:
        return linkTypes.DRILLDOWN;
      case linkTypes.LOOKUP:
        return linkTypes.LOOKUP;
      case linkTypes.SEARCH:
        return linkTypes.SEARCH;
      default:
        console.error('Unexpected link type: ', linkType);
        return '';
    }
  }

  async function createToken(apiKey) {
    let callback = function (response) {
      if (response.token) {
        apiService.setToken(response.token);
        callbacks.onTokenReceived();
        getJSControlInit();
      } else {
        console.error('Could not get token');
      }
    };

    let createTokenResponse = await apiService.fetch(
      endpoints.CREATE_TOKEN,
      null,
      callback,
      null,
      apiKey
    );

    if (!createTokenResponse.ok) {
      hideSearchBar();
      showCustomerFields();
    }
  }

  function setInputValue(value) {
    if (settings.wrapperIsInput) {
      inlineElement.value = value;
      inlineElement.dispatchEvent(new Event('input'));
    } else {
      input.value = value;
      input.dispatchEvent(new Event('input'));
    }
  }

  function setPlaceholderText(placeholder) {
    if (callbacks.onPreSetPlaceholderText() !== false) {
      let placeholderTextResponse = callbacks.onPostSetPlaceholderText(placeholder);
      placeholder = placeholderTextResponse ? placeholderTextResponse : placeholder;
      if (settings.wrapperIsInput) {
        inlineElement.placeholder = placeholder;
      } else if (input) {
        input.placeholder = placeholder;
      }
    }
  }

  function hideSearchBar() {
    if (callbacks.onHideSearchBar() !== false) {
      if (input) {
        selector.elementQuerySelector('.autoaddress-input-control').style.display = 'none';
      }
    }
  }

  function hideCustomerFields() {
    if (
      callbacks.onHideCustomerFields() !== false ||
      options.integrationType === integrationType.AUTOADDRESS_FORM
    ) {
      if (options.customerFieldId) {
        if (document.querySelector('#' + options.customerFieldId) != null) {
          document.querySelector('#' + options.customerFieldId).style.display = 'none';
        }
      } else if (options.customerFieldSelector) {
        if (document.querySelectorAll(options.customerFieldSelector)) {
          document.querySelectorAll(options.customerFieldSelector).forEach(hideField => {
            hideField.style.display = 'none';
          });
        }
      }
    }
  }

  function showCustomerFields() {
    if (callbacks.onShowCustomerFields() !== false) {
      if (document.querySelector('#' + options.customerFieldId) != null) {
        document.querySelector('#' + options.customerFieldId).style.display = 'unset';
      } else if (options.customerFieldSelector) {
        if (document.querySelectorAll(options.customerFieldSelector)) {
          document.querySelectorAll(options.customerFieldSelector).forEach(hideField => {
            hideField.style.display = 'unset';
          });
        }
      }
    }
  }

  function handleJSInitResponse(response, currentIntegrationType) {
    let link = Utils.getHrefFromResponse(response, endpoints.ADDRESS_FORM_LAYOUT);
    addressFormLayoutLink = link;
    if (options.isSearchAllowed !== false && response.isSearchAllowed) {
      setPlaceholderText(settings.searchMessage);
    } else if (currentIntegrationType == integrationType.AUTOADDRESS_FORM) {
      if (link) {
        buildAddressFormFromLink(link);
      } else {
        errorHandler.handleError(
          errorHandler.error.HREF_MISSING_FROM_RESPONSE,
          endpoints.ADDRESS_FORM_LAYOUT
        );
      }
    } else {
      hideSearchBar();
      showCustomerFields();
    }
  }

  function handleLookupResponse(response, currentIntegrationType) {
    // Store cached LookUp api response to be used for on submit functionality
    settings.selectedAddress = response;
    if (currentIntegrationType == integrationType.AUTOADDRESS_FORM) {
      let link = Utils.getHrefFromResponse(response, endpoints.ADDRESS_FORM_LAYOUT);
      buildAddressFormFromLink(link, response);
    }
    if (integrationType.COMBO.includes(currentIntegrationType)) {
      clearList();
      hideSearchBar();
      showCustomerFields();
    }
    if (currentIntegrationType == integrationType.INLINE) {
      clearList();
      showCustomerFields();
    }
  }

  function handleSelectEnterManually(manualEntryElement, currentIntegrationType) {
    showingForm = true;

    if (integrationType.COMBO.includes(currentIntegrationType)) {
      // TODO: create showForm() method to check integration requirements
      clearList();
      hideSearchBar();
      showCustomerFields();
      return;
    }

    if (integrationType.INLINE.includes(currentIntegrationType)) {
      detachListenerForInput();
      return;
    }

    let link = manualEntryElement.getAttribute('data-link');
    buildAddressFormFromLink(link);
  }

  // Returned functions
  function setLanguage(language) {
    options.language = language;
  }

  function setCountry(country) {
    options.country = country;
  }

  function setLatitudeAndLongitude(latitude, longitude) {
    if (latitude != null && longitude != null) {
      options.latitude = latitude.toString();
      options.longitude = longitude.toString();
    } else {
      options.latitude = latitude;
      options.longitude = longitude;
    }
  }

  function setH3Index(h3Index) {
    options.h3Index = typeof h3Index == 'number' ? h3Index.toString() : h3Index;
  }

  function setAutoScrollOffset(autoScrollOffset) {
    options.autoScrollOffset = autoScrollOffset;
  }

  function setSearch(search) {
    if (hasInitCompleted()) {
      setInputValue(search);
    } else {
      callbacks.onPostJsInit = () => {
        setInputValue(search);
      };
    }
  }

  function showAutoaddressControl() {
    if (container) container.classList.remove('autoaddress-hidden');
    if (inlineElement) {
      setPlaceholderText(settings.searchMessage);
      container.classList.remove('autoaddress-hidden');
      attachListenerForInput();
    }
    callbacks.onShowSearchBar();
  }

  function hideAutoaddressControl() {
    if (container) container.classList.add('autoaddress-hidden');
    if (inlineElement) {
      setPlaceholderText(settings.initialPlaceholder);
      container.classList.add('autoaddress-hidden');
      detachListenerForInput();
    }
    callbacks.onHideSearchBar();
  }

  function reset(newOptions) {
    if (inlineElement) {
      detachListenerForInput();
    }
    container.remove();
    container = null;
    showingForm = false;

    if (newOptions !== null && typeof newOptions === 'object') {
      optionData = Utils.extend(optionData, newOptions);
    }

    init(optionData);
  }

  function getVersion() {
    return settings.version;
  }

  function triggerFormatEnteredAddress(address) {
    const form = selector.elementQuerySelector('#autoaddress-manual-entry-form');
    let params = address ? address : addressFormBuilder?.getFormFieldParamters(form);
    if (params) {
      callbacks.onSubmitToFormatEnteredAddress(params);
    }
  }

  async function fetchReformatEnteredAddress(address) {
    const form = selector.elementQuerySelector('#autoaddress-manual-entry-form');
    let params = address ? address : addressFormBuilder?.getFormFieldParamters(form);

    if (!params) {
      return null;
    }

    params = {
      ...params,
      language: options.language,
      country: options.country,
      selectedCountry: settings.selectedCountry ?? null,
      selectedLanguage: settings.selectedLanguage ?? null,
    };

    let result;
    await apiService.fetch(endpoints.FORMAT_ENTERED_ADDRESS, params, data => {
      result = data;
    });
    return result;
  }

  /**
   * Returns the address result from the lookup API (and/or the reformat API)
   * If we have a lookup response, we merge the address data with the reformat data to return a combined json
   */
  async function getAddressResult() {
    let reformatData = null;

    if (settings.hasFormValuesChanged || !settings.selectedAddress) {
      reformatData = await fetchReformatEnteredAddress();
    }
    // If I have a lookup response, merge the address data with the reformat data
    return Utils.getMergedAddress(settings.selectedAddress, reformatData);
  }

  function getValidation() {
    let isValid = addressFormBuilder.validateAutoaddressForm();
    return isValid;
  }

  /**
   * Set the Autoaddress address form with the address object passed in (address object should be in the format of the address form)
   * @param {*} address
   * @returns {Promise}
   */
  async function setAddress(address) {
    if (addressFormLayoutLink) {
      await new Promise(resolve => {
        // Build the address form from the link and then populate the form with the address
        buildAddressFormFromLink(addressFormLayoutLink, null, () => {
          addressFormBuilder.populateFormWithAddress(address);
          resolve();
        });
      });
    } else {
      return Promise.resolve();
    }
  }

  return {
    setLanguage: setLanguage,
    setCountry: setCountry,
    setLatitudeAndLongitude: setLatitudeAndLongitude,
    setH3Index: setH3Index,
    setSearch: setSearch,
    setAutoScrollOffset: setAutoScrollOffset,
    showAutoaddressControl: showAutoaddressControl,
    hideAutoaddressControl: hideAutoaddressControl,
    reset: reset,
    submitToFormatEnteredAddress: submitToFormatEnteredAddress,
    getVersion: getVersion,
    triggerFormatEnteredAddress: triggerFormatEnteredAddress,
    fetchReformatEnteredAddress: fetchReformatEnteredAddress,
    getAddressResult: getAddressResult,
    getValidation: getValidation,
    setAddress,
  };
}
